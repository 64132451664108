import Swiper from 'swiper'

class MainBanner {
  /**
   * @param {HTMLElement} element - The HTMLElement this module is constructed upon
   */
  constructor(element) {
    this.element_ = element
    this.init()
  }

  /**
   * Construct module.
   */
  init() {
    const bannerSwiper = new Swiper('.main-banner__swiper-container', {
      autoplay: true,
      effect: 'fade',
      loop: true,
      slidesPerView: 1,
      watchOverflow: true,
      pagination: {
        el: '.main-banner__swiper-pagination',
        clickable: true
      }
    })
  }
}

// Exports
export default MainBanner
