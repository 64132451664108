/* eslint-disable complexity */
/* eslint-disable no-undef */

import IMask from 'imask'
class MainBanner {
  /**
   * @param {HTMLElement} element - The HTMLElement this module is constructed upon
   */
  constructor(element) {
    this.element_ = element
    this.phoneInput_ = this.element_.querySelector('input[name="phone"]')

    this.init()
  }

  instanceFields() {
    IMask(this.phoneInput_, { mask: '(00) 00000-0000' })
  }

  /**
   * Construct module.
   */
  init() {
    this.instanceFields()
  }
}

// Exports
export default MainBanner
