class Header {
  /**
   * @param {HTMLElement} element - The HTMLElement this module is constructed upon
   */
  constructor(element) {
    this.element_ = element
    this.button_ = element.querySelector('.header__button')
    this.black_ = element.getAttribute('black')
    this.hamburguer_ = element.querySelector('.header__hamburguer-button')
    this.modal_ = document.querySelector('.modal-menu')

    this.init()
  }

  addClasses() {
    this.element_.classList.add('header--black')
    this.button_.classList.remove('button--black')
  }

  removeClasses() {
    this.element_.classList.remove('header--black')
    this.button_.classList.add('button--black')
  }

  checkForClasses() {
    if (this.black_ === 'true') {
      this.addClasses()
    } else {
      if (window.pageYOffset > 10) this.addClasses()
      else this.removeClasses()
    }
  }

  /**
   * Construct module.
   */
  init() {
    this.checkForClasses()
    window.addEventListener('scroll', () => {
      this.checkForClasses()
    })
    this.hamburguer_.addEventListener('click', () => {
      this.modal_.classList.add('modal--show')
    })
  }
}

// Exports
export default Header
