class OurStory {
  /**
   * @param {HTMLElement} element - The HTMLElement this module is constructed upon
   */
  constructor(element) {
    this.element_ = element
    this.buttons_ = element.querySelectorAll('[data-button]')
    this.contents_ = element.querySelectorAll('[data-content]')

    this.init()
  }

  /**
   * Construct module.
   */
  init() {
    console.log(this.buttons_)
    this.buttons_.forEach(button => {
      const data = button.getAttribute('data-button')
      button.addEventListener('click', () => {
        this.buttons_.forEach(btn => {
          btn.querySelector('a').classList.remove('bordered-link--active')
          btn.querySelector('a').classList.add('bordered-link--no-line')
        })
        button.querySelector('a').classList.remove('bordered-link--no-line')
        button.querySelector('a').classList.add('bordered-link--active')
        this.contents_.forEach(content => (content.style.display = 'none'))
        this.element_.querySelector(`[data-content="${data}"]`).style.display =
          'block'
      })
    })
  }
}

// Exports
export default OurStory
