/* eslint-disable complexity */
/* eslint-disable no-undef */
class MainBanner {
  /**
   * @param {HTMLElement} element - The HTMLElement this module is constructed upon
   */
  constructor(element) {
    this.element_ = element
    this.itemsToShow = window.innerWidth < 768 ? 9 : 10
    this.trigger_ = element.querySelector('.brands-list__trigger')
    this.modal_ = element.querySelector('.brands-list__modal')
    this.allButtons_ = element.querySelectorAll('.brands-list__button')
    this.modalClose_ = element.querySelector('.brands-list__modal-close')

    this.modalImage_ = this.modal_.querySelector('.brands-list__modal-image')
    this.modalMainImage_ = this.modal_.querySelector(
      '.brands-list__modal-main-image'
    )
    this.modalTitle_ = this.modal_.querySelector('.brands-list__modal-title')
    this.modalText_ = this.modal_.querySelector('.brands-list__modal-text')
    this.modalLink_ = this.modal_.querySelector('.brands-list__modal-button-2')

    this.init()
  }

  showItems() {
    for (let i = 0; this.itemsToShow > i; i++) {
      if (this.allButtons_[i]) this.allButtons_[i].style.display = 'block'
    }
  }

  openModal(target) {
    this.modal_.classList.add('modal--show')
    const image = target.getAttribute('data-image')
    const title = target.getAttribute('data-title')
    const text = target.getAttribute('data-text')
    const link = target.getAttribute('data-link')
    this.modalImage_.setAttribute('src', image)
    this.modalImage_.setAttribute('alt', title)
    this.modalMainImage_.setAttribute('src', image)
    this.modalMainImage_.setAttribute('alt', title)
    this.modalTitle_.innerHTML = title
    this.modalText_.innerHTML = text
    this.modalLink_.setAttribute('href', link)
  }

  /**
   * Construct module.
   */
  init() {
    this.modalClose_.addEventListener('click', () => {
      this.modal_.classList.remove('modal--show')
    })
    this.allButtons_.forEach(btn => {
      if (btn) {
        btn.style.display = 'none'
        btn.addEventListener('click', e => this.openModal(e.target))
      }
    })
    if (this.allButtons_.length <= this.itemsToShow)
      this.trigger_.style.display = 'none'
    this.showItems()
    this.trigger_.addEventListener('click', e => {
      this.itemsToShow = 200;
      this.showItems()
      this.trigger_.style.display = 'none'
      //if (this.allButtons_.length >= this.itemsToShow)
    })
  }
}

// Exports
export default MainBanner
