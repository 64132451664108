import Swiper from 'swiper'

class TeamSlider {
  /**
   * @param {HTMLElement} element - The HTMLElement this module is constructed upon
   */
  constructor() {
    this.init()
  }

  init() {
    const teamSwiper = new Swiper('.team-slider__swiper-container', {
      slidesPerView: 1,
      spaceBetween: 16,
      watchOverflow: true,
      breakpoints: {
        769: {
          slidesPerView: 2
        },
        1200: {
          slidesPerView: 3
        }
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      pagination: {
        el: '.team-slider__swiper-pagination',
        clickable: true
      }
    })
  }
}

// Exports
export default TeamSlider
