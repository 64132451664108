import Swiper from 'swiper'

class CloudServices {
  /**
   * @param {HTMLElement} element - The HTMLElement this module is constructed upon
   */
  constructor(element) {
    this.element_ = element
    this.init()
  }

  /**
   * Construct module.
   */
  init() {
    new Swiper('.cloud-services__swiper-container', {
      slidesPerView: 1,
      watchOverflow: true,
      spaceBetween: 20,
      breakpoints: {
        769: {
          slidesPerView: 2,
          spaceBetween: 100
        }
      },
      pagination: {
        el: '.cloud-services__swiper-pagination',
        clickable: true
      }
    })
  }
}

// Exports
export default CloudServices
