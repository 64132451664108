import Elevator from 'elevator.js'

class Footer {
  /**
   * @param {HTMLElement} element - The HTMLElement this module is constructed upon
   */
  constructor(element) {
    this.element_ = element
    this.init()
  }

  /**
   * Construct module.
   */
  init() {
    new Elevator({
      element: this.element_.querySelector('.footer__link--cloud'),
      targetElement: document.querySelector('#cloud'),
      verticalPadding: 108,
      duration: 1000
    })
  }
}

// Exports
export default Footer
