import Swiper from 'swiper'

class ProductsSlider {
  /**
   * @param {HTMLElement} element - The HTMLElement this module is constructed upon
   */
  constructor() {
    this.init()
  }

  init() {
    const productsSwiper = new Swiper('.products-slider__swiper-container', {
      slidesPerView: 1,
      spaceBetween: 16,
      watchOverflow: true,
      breakpoints: {
        769: {
          slidesPerView: 2
        },
        1200: {
          slidesPerView: 4
        }
      },
      pagination: {
        el: '.products-slider__swiper-pagination',
        clickable: true
      }
    })
  }
}

// Exports
export default ProductsSlider
