/* eslint-disable complexity */
/* eslint-disable no-undef */
class Modal {
  /**
   * @param {HTMLElement} element - The HTMLElement this module is constructed upon
   */
  constructor(element) {
    this.element_ = element
    this.shadow_ = element.querySelector('.modal__shadow')

    this.init()
  }

  /**
   * Construct module.
   */
  init() {
    this.shadow_.addEventListener('click', () => {
      this.element_.classList.remove('modal--show')
    })
  }
}

// Exports
export default Modal
