class Menu {
  /**
   * @param {HTMLElement} element - The HTMLElement this module is constructed upon
   */
  constructor(element) {
    this.element_ = element
    this.close_ = element.querySelector('.menu__close')
    this.modal_ = document.querySelector('.modal-menu')

    this.init()
  }

  init() {
    this.close_.addEventListener('click', () => {
      this.modal_.classList.remove('modal--show')
    })
  }
}

// Exports
export default Menu
