class EquipmentRental {
  /**
   * @param {HTMLElement} element - The HTMLElement this module is constructed upon
   */
  constructor(element) {
    this.element_ = element
    this.formButton_ = element.querySelector('.equipment-rental__open-form')
    this.modal_ = element.querySelector('.equipment-rental__form-modal')
    this.close_ = element.querySelector('.equipment-rental__close')

    this.init()
  }

  /**
   * Construct module.
   */
  init() {
    this.formButton_.addEventListener('click', () => {
      this.modal_.classList.add('modal--show')
    })

    this.close_.addEventListener('click', () => {
      this.modal_.classList.remove('modal--show')
    })
  }
}

// Exports
export default EquipmentRental
